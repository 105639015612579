import React from 'react'
import { graphql } from 'gatsby'
import ArticleComponent from '../components/Blog/ArticleComponent'
import FocusComponent from '../components/Blog/FocusComponent'
import useBreakpoint from '../customHooks/useBreakpoint'
import { LoadableQuintNavHeader } from '../components/LoadableComponents'
import { navigate } from 'gatsby-link'
import Seo from '../components/SEO'

const tabs = [
  'All',
  'Design',
  'Development',
  'Business'
]

const tabsUri = [
  '/',
  '/design',
  '/development',
  '/business'
]

const BlogPage = ({ data, pageContext }) => {
  if (!data || !pageContext) {
    return ''
  }

  const isMobile = useBreakpoint('sm', false)

  const pageData = data.prismicBlogPage.data
  const blogPosts = pageContext[Object.keys(pageContext)]

  const thisWeek = blogPosts.slice(0, 2)

  const handleTabSelect = (index) => {
    navigate(`/blog${tabsUri[index]}`)
  }

  return (
    <>
      <Seo title={pageData.meta_title} description={pageData.meta_description} image={pageData.meta_image && pageData.meta_image.url} />
      <LoadableQuintNavHeader
        blog tabs={tabs}
        selectedTab={0} color='#25317B' setSelectedTab={handleTabSelect} backgroundColor='#EFF8FF' borderColor='#EFF8FF' style={{ marginTop: 85 }} />
      <div className='content-container' style={{ marginTop: isMobile ? 140 : 157, backgroundColor: '#F7F7F7' }}>
        <div className='quint-container-lg' style={{ overflowY: 'hidden', padding: isMobile ? '32px 0' : '72px 0', width: '100%' }}>
          <div
            className='quint-body-bold color-primary font-main'
            style={isMobile
              ? { fontSize: 18, marginBottom: 18, textAlign: 'left', paddingLeft: 24 }
              : { fontSize: 24, marginBottom: 24 }}>
            Latest articles
          </div>
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', marginBottom: 48 }}>
            {thisWeek.map((article, index) => {
              const articleData = article.node.data
              const link = `/blog/${articleData.section.toLowerCase().replace(/ /g, '-')}/${article.node.uid.toLowerCase().replace(/ /g, '-')}`
              return (
                <ArticleComponent
                  size='md'
                  background={articleData.background}
                  icon={articleData.icon}
                  date={articleData.date}
                  name={articleData.name}
                  tags={article.node.tags}
                  content={articleData.content}
                  subtitle={articleData.title}
                  link={link}
                  style={isMobile ? { marginBottom: 24, maxWidth: '100%' } : {}}
                  key={index}
                />
              )
            })}
          </div>
          {pageData.people_seem_to_read_these_title &&
            <div
              className='quint-body-bold color-primary font-main'
              style={isMobile
                ? { fontSize: 18, marginBottom: 18, textAlign: 'left', paddingLeft: 24 }
                : { fontSize: 24, marginBottom: 24 }}>
              {pageData.people_seem_to_read_these_title}
            </div>}
          {pageData.people_seem_to_read_these &&
            <div style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              justifyContent: 'space-between',
              marginBottom: 96,
              padding: isMobile ? 24 : 'unset'
            }}>
              {pageData.people_seem_to_read_these.map((article, index) => {
                const articleData = article.article.document.data
                const articleData2 = article.article.document
                const link = `/blog/${articleData.section.toLowerCase().replace(/ /g, '-')}/${articleData2.uid.toLowerCase().replace(/ /g, '-')}`
                return (
                  <ArticleComponent
                    size='sm'
                    background={articleData.background}
                    icon={articleData.icon}
                    date={articleData.date}
                    name={articleData.name}
                    tags={articleData2.tags}
                    content={articleData.content}
                    subtitle={articleData.title}
                    link={link}
                    style={isMobile ? { marginBottom: 24, maxWidth: '100%' } : {}}
                    key={index}
                  />
                )
              })}
            </div>}
          <div style={{ height: isMobile ? 'unset' : 56, padding: '12px 32px', backgroundColor: isMobile ? '#f7f7f7' : '#fff', textAlign: 'left' }}>
            <div className='quint-body-bold color-primary font-main' style={{ fontSize: 24 }}>
              {pageData.on_focus_title}
            </div>
          </div>
          <FocusComponent data={pageData.onfocusgroup} />
          {!isMobile && <div style={{ height: 56, padding: '12px 32px', backgroundColor: '#fff' }} />}
        </div>
      </div>
    </>
  )
}

export default BlogPage

export const blogQuery = graphql`
  query MyQuery {
    prismicBlogPage {
      data {
        meta_title
        meta_description
        meta_image {
          url
        }
        on_focus_title
        onfocusgroup {
          on_focus_article {
            document {
              ... on PrismicBlogPost {
                id
                uid
                tags
                data {
                  background {
                    url
                  }
                  content {
                    raw
                    text
                  }
                  date
                  icon {
                    url
                  }
                  name
                  section
                  title
                }
              }
            }
          }
        }
        people_seem_to_read_these_title
        people_seem_to_read_these {
          article {
            document {
              ... on PrismicBlogPost {
                id
                uid
                tags
                data {
                  background {
                    url
                    alt
                    fluid(maxWidth: 850) {
                      src
                      base64
                      aspectRatio
                      sizes
                      srcSetWebp
                      srcWebp
                      srcSet
                    }
                  }
                  content {
                    raw
                    text
                  }
                  date
                  icon {
                    url
                    alt
                  }
                  name
                  title
                  section
                }
              }
            }
          }
        }
      }
    }
  }
`

