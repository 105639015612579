import React from 'react'
import PersonComponent from './PersonComponent'
import useBreakpoint from '../../customHooks/useBreakpoint'
import { Link } from 'gatsby'
import { navigate } from 'gatsby-link'

export default function FocusComponent(props) {
  const { data, ...rest } = props

  const singleArticle = []
  const restArticles = []

  data.forEach((article, index) => {
    if (index === 0) {
      singleArticle.push(article)
      return
    }
    restArticles.push(article)
  })

  const singleArticleData = singleArticle[0].on_focus_article.document

  const isMobile = useBreakpoint('xs', false)

  return (
    <div style={{ width: '100%', height: 512, display: 'flex', flexDirection: isMobile ? 'column' : 'row', backgroundColor: isMobile ? '#f7f7f7' : '#fff', ...rest }}>
      <div
        style={{
          flex: 2,
          backgroundImage: `url(${singleArticleData.data.background && singleArticleData.data.background.url})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          objectFit: 'cover',
          display: 'flex',
          alignItems: 'flex-end',
          position: 'relative',
          padding: '16px 32px',
          textDecoration: 'none',
          cursor: 'pointer'
        }} onClick={() => navigate(`/blog/${singleArticleData.data.section.toLowerCase().replace(/ /g, '-')}/${singleArticleData.uid}`)}>
        <div style={{
          position: 'absolute',
          bottom: -1,
          left: 0,
          right: 0,
          height: 112,
          zIndex: 1,
          background: 'linear-gradient(transparent, black)',
        }} />
        <div style={{ zIndex: 9 }}>
          <div className='font-main quint-body-bold color-white' style={{ maxWidth: 440, marginBottom: 16 }}>
            {singleArticleData.data.title}
          </div>
          <PersonComponent
            icon={singleArticleData.data.icon}
            date={singleArticleData.data.date}
            size='md'
            content={singleArticleData.data.content && singleArticleData.data.content.text}
            nameColor='white'
            dateColor='white'
            flexRow
            name={singleArticleData.data.name}
            tags={singleArticleData.tags} />
        </div>
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', padding: isMobile ? '32px' : '0 0 0 16px' }}>
        {restArticles && restArticles.map((item, index) => {
          const restArticleData = item.on_focus_article.document
          return (
            <Link
              style={{
                marginBottom: 16,
                textDecoration: 'none',
              }}
              to={`/blog/${restArticleData.data.section.toLowerCase().replace(/ /g, '-')}/${restArticleData.uid}`}
              key={index}>
              <div className='font-main color-primary quint-body-bold' style={{ lineHeight: '19px', fontWeight: 600, textAlign: 'left' }}>
                {restArticleData.data.title}
              </div>
              <div className='font-main color-primary quint-text-small' style={{ textDecoration: 'underline' }}>
                {restArticleData.data.name}
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
